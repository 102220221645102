import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, redirect } from "react-router-dom";
import myContract from "./abis/myContract.json";
import Marketplace from "./abis/Marketplace.json";

import Header from "./components/Header";
import AddNFT from "./components/AddNFT";
import ShowNft from "./components/ShowNft";
import Login from "./components/Login";
import Listing from "./components/Listing";

import WalletConnectProvider from "@walletconnect/web3-provider"
import { Network, isValidAccount } from "./components/HelperFunctions";
import Web3Model from "web3modal";
import axios from 'axios';

const rpc = {};
for (let net in Network){
	rpc[net] = Network[net]['rpcUrl']
}

const providerOptions = {
	walletconnect: {
		package: WalletConnectProvider,
		options: {
		  infuraId: process.env.PROJECT_ID,
		  rpc: rpc
		}
	},
}



const Web3 = require('web3');
const App = () => {
	const [account, setAccount] = useState('')
	const [contract, setContract] = useState('')
	const [marketplaceContract, setMarketplaceContract] = useState('')
	const [address, setAddress] = useState('')
	const [nftMetadata, setNftMetadata] = useState([]);
	const [model, setModel] = useState('');
	const [loadingMetadata, setLoadingMetadata] = useState(false);
	const [listedNft, setListedNft] = useState([]);
	const [loadingListing, setLoadingListing] = useState(false);

	const Disconnect = async (HomeRedirect=true) => {
		setAccount('');
		setContract('');
		setAddress('');
		setNftMetadata('');
		setNftMetadata([]);
		if(model){
			await model.clearCachedProvider();
		}
		if(HomeRedirect) {
			window.localStorage.clear();
			window.location.replace('/');
		}
	}

	
	useEffect(()=>{
		window.onload = async () => {
			if(window.location.pathname !== '/'){
				if(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")){
					ConnectMeta('same').then();
				}
				else{
					Disconnect()
					return
				}       	
			}
		}
	},[])

	useEffect(()=>{
		if(contract){
			getAllNFT()
		}
	},[contract, address])

	async function addListeners(web3ModalProvider) {
		web3ModalProvider.on("accountsChanged", (accounts) => {
			if(accounts.length < 1){
				Disconnect();
				return

			}
			if(window.location.pathname !== '/'){
				window.location.reload();
			}
		});
		web3ModalProvider.on("chainChanged", (chainId) => {
			if(window.location.pathname !== '/'){
				window.location.reload();
			}
		});
		web3ModalProvider.on("disconnect", (error) => {
			Disconnect();
			return
		});
	}

	const ProviderInit = async (web3modalInstance, chain) =>{
		window.w3= new Web3(web3modalInstance);
		const web3 = window.w3
	
		var accounts = await web3.eth.getAccounts();
		if(accounts.length < 1){
			alert('no account found..!');
			return;
		}
		if(!(await isValidAccount(accounts[0]))){
			alert("Your Address IS Not Valid..!");
			await Disconnect();
			return
		}
		setAccount(accounts[0])
		
		const networkID = await web3.eth.net.getId();
		let networkData, marketplaceData;
		if(chain === 'same'){
			networkData = myContract.networks[networkID];
			marketplaceData = Marketplace.networks[networkID];
		}
		else{
			networkData = myContract.networks[chain];
			marketplaceData = Marketplace.networks[chain];
		}
		if (networkData) {
			if(chain !== networkID && chain !== 'same'){
				try{
					await web3modalInstance.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: Web3.utils.toHex(chain) }],
					})
				}catch(err){
					await web3modalInstance.request({
						method: 'wallet_addEthereumChain',
						params: [
							{
								chainName: Network[chain]['chainName'],
								chainId: web3.utils.toHex(chain),
								nativeCurrency: Network[chain]['nativeCurrency'],
								rpcUrls: [Network[chain]['rpcUrl']]
							}
						]
					});
				}
			}
			
			const abi = myContract.abi;
			const addressOwn = networkData.address;
			setAddress(addressOwn)

			const contracts = new web3.eth.Contract(abi, addressOwn)
			const tempMarketPlaceContract = new web3.eth.Contract(Marketplace.abi, marketplaceData.address)

			setContract(contracts)
			setMarketplaceContract(tempMarketPlaceContract)
			if (window.location.pathname === '/'){
				return true;
			}
		}
	}

	const ConnectMeta = async (chain) => {
		try{
			const web3modal = new Web3Model({
				cacheProvider: true,
				providerOptions,
			});
			setModel(web3modal);

			const web3modalInst = await web3modal.connect();
			await addListeners (web3modalInst);
			if (web3modalInst){
				return await ProviderInit(web3modalInst, chain);
			}
			else{
				alert('provider not find..!')
				return false;
			}
		}
		catch(err){
			console.error(err);
		}
	}

	const mint = async (NFT) => {
		let isMinted = false;
		try{
			await contract.methods.mintNFT(NFT).send({ from: account });
			isMinted = true;
		}
		catch(err){
			isMinted = false;
			if(err.code === 4001){
				alert('Minting request denied by user..!')
			}
			else{
				alert('error occurs while minting..!')
			}
		}
		await getAllNFT()
		return isMinted;
	}

	const listNFT = async (tokenId, price) => {
		if(marketplaceContract !== '' && address !== '' && account !== ''){
			let listingFee = await marketplaceContract.methods.getListingFee().call()
			listingFee = listingFee.toString()
			const isListed = await marketplaceContract.methods.listNft(address, tokenId.toString(), window.w3.utils.toWei(price.toString(), "ether")).send({ from: account, value: listingFee })
			setTimeout(async () => {await getAllNFT()},1000)
			return true
		}
		else{
			alert('error in listing')
			return false
		}
	}

	const getAllNFT = async () => {
		setLoadingMetadata(true)
		const totalNumberOfTokens = await contract.methods.getTokenCount().call();
		let metaObj = [] ;
		for (let i = 1; i <= totalNumberOfTokens; i++) {
			//fetch json and add it to return array
			const fetchedData = await contract.methods.tokenURI(i).call();
			const Owner = await contract.methods.ownerOf(i).call();
			let metadata_url = fetchedData.split('/');
			metadata_url = 'https://ipfs.io/ipfs/'+metadata_url[metadata_url.length-1];
			let jsn_ = await fetch(metadata_url.toString());
			if (jsn_.status === 200){
				jsn_ = await jsn_.json();
				jsn_['owner'] = Owner;
				jsn_['tokenId'] = i;
				metaObj.push(jsn_);
			}
		}
		setNftMetadata(metaObj)
		setLoadingMetadata(false)
	}

	const getListedNFT = async () => {
		setLoadingListing(true);
		if(marketplaceContract === '' || contract === '' || account === ''){
			window.location.replace('/home');
			return false
		}
		const listings = await marketplaceContract.methods.getListedNfts().call()
		const nfts = await Promise.all(listings.map(async (i) => {
			try {
			  const tokenURI = await contract.methods.tokenURI(i.tokenId).call()
			  let urlarr = tokenURI.split('/');
		      urlarr = 'https://ipfs.io/ipfs/'+urlarr[urlarr.length-1];
			  const meta = await axios.get(urlarr)
			  const nft = {
				price: i.price,
				tokenId: i.tokenId,
				seller: i.seller,
				owner: i.buyer,
				image: meta.data.image,
				name: meta.data.name,
				description: meta.data.description,
			  }
			  return nft
			} catch(err) {
			  console.log(err)
			  return null
			}
		}))
		setListedNft(nfts.filter(nft => nft !== null));
		setLoadingListing(false);
	}

	const buyNFT = async (nft) =>{
		if(marketplaceContract === '' || contract === '' || account === ''){
			window.location.replace('/home');
			return false
		}
		const isBought = await marketplaceContract.methods.buyNft(address, nft.tokenId).send({ from: account, value: nft.price });
		getListedNFT();
		return true
	}

	return (
		<BrowserRouter>
			<Routes>
				<Route exact path="/" element={<Login ConnectMeta={ConnectMeta} />} />
				<Route path="/home" element={
					<>
						<Header account={account} Disconnect={Disconnect} />
						<ShowNft nftMetadata={nftMetadata} account={account} loadingMetadata={loadingMetadata} listNFT={listNFT} getAllNFT={getAllNFT} contract={contract} />
					</>
				} />
				<Route path="/add" element={
					<>
						<Header account={account} Disconnect={Disconnect} />
						<AddNFT mint={mint}  />
					</>
				} />
				<Route path="/listing" element={
					<>
						<Header account={account} Disconnect={Disconnect} />
						<Listing getListedNFT={getListedNFT} buyNFT={buyNFT} account={account} listedNft={listedNft} loadingListing={loadingListing} />
					</>
				} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;