import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { Modal, Button, Form } from "react-bootstrap";

export default function ShowNft(props) {
	const [show, setShow] = useState(false);
	const [tokenId, setTokenId] = useState('');
	const [price, setPrice] = useState('');
	let priceInput ;

	const  reset = () => {
		setPrice('');
		setTokenId('');
		setShow(false);
	}

	const handleShow = (tokenIdd) => {
		setTokenId(tokenIdd);
		setShow(true)
	};

    const navigate = useNavigate();
	useEffect(()=>{
		if(props.contract){
			props.getAllNFT().then(()=>{
			})
		}
	},[])

	const addRedirect = () => {
		navigate('/add');
	}

	const submitHandler = async () => {
		await props.listNFT(tokenId, priceInput.value)
		reset()
	}

	if (!props.loadingMetadata) {
		return (
			<>
				<Modal show={show}>
					<Modal.Header>
					<Modal.Title>Login Form</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form onSubmit={
								async (event) => {
									event.preventDefault();
									// await submitHandler();
								}
							}>
							<div className="row">
								<div className="col-12">
									<label className="form-label">Price</label>
									<input type='number' className="form-control" placeholder='Enter Amount'
									ref={(input) => priceInput = input } / > 
								</div>
							</div>
						</form>
					</Modal.Body>
					<Modal.Footer>
					<Button variant="secondary" onClick={()=>reset()}>Close Modal</Button>
					<Button variant="primary" onClick={async ()=> await submitHandler()}>List</Button>
					</Modal.Footer>
				</Modal>

				<div className="card">
					<div className="row d-flex flex-row p-3" >
						<div className="col-11">
							<h1>Minted NFTS</h1>
						</div>
						<div className="col-1">
							<button type="button" className="btn btn-primary d-flex-inline" style={{ width:"5rem" }} onClick={() => addRedirect()}>Add</button>
						</div>
					</div>
					<div className='gap-3 row p-3' style={{ display: "flex" }}>			
						{	(!props.nftMetadata) ? <h5 className="card-title">No NFT Found</h5> : 
							props.nftMetadata.map((element, index) => {
								return (
								<div className="card p-2" style={{width: "18rem", background:'#ededed'}} key={index}>
									<img src={element.image} className="card-img-top" alt="ima" style={{height:'180px'}} />
									<div className="card-body">
										<h5 className="card-title">{element.name}</h5>
										<p className="card-text">
											Owner : {element.owner} {(props.account === element.owner) ? '(You)' : ''}
											</p>
										<p className="card-text">{element.description}</p>
										{(props.account === element.owner) ? <button className='btn btn-success' onClick={()=>handleShow(element.tokenId)}>List</button>: ''}
									</div>
								</div>)
							})
						}
					</div>
				</div>
			</>
		)
	}
	else{
		return <Loader />
	}
}
