import React from 'react'
import { PushSpinner } from "react-spinners-kit";

export default function Loader(props) {
    if(props.text){
        return(<div>
            <div className="d-flex justify-content-center mt-5">
                <h3>{props.text}</h3>
            </div>
            <div className="d-flex justify-content-center mt-5">
                <PushSpinner size={50} color="#686769" loading={true} /> 
            </div>
        </div> )
    }
  else{
    return(
        <div className="d-flex justify-content-center mt-5">
            <PushSpinner size={50} color="#686769" loading={true} /> 
        </div>
    )
}
}
