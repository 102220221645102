import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

export default function Header(props) {
    const navigate = useNavigate();

	const disconnectWallet = async () => {
		const disconnected = await props.Disconnect();
		if (disconnected){
			navigate('/');
		}
	}

	return (
		<nav className="navbar navbar-expand-lg bg-dark navbar-dark">
			<div className="container-fluid">
				<Link className="navbar-brand" to="/home">{props.title}</Link>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarNav">
					<ul className="navbar-nav">
						<li className="nav-item">
							<Link className="nav-link active" aria-current="page" to="/home">Home</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/add">Add</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/listing">Listing</Link>
						</li>
					</ul>
					<div className="d-flex ms-auto">
						<li className='nav-link me-2'>
							<Link className="nav-link">{props.account}</Link>
						</li>
						<li className='nav-link me-2'>
							<button className="btn btn-danger" onClick={()=>disconnectWallet()}>disconnect</button>
						</li>
					</div>
				</div>
			</div>
		</nav>
	)
}
