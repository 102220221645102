import React, {useState, useEffect} from 'react'
import Loader from './Loader';

export default function Listing(props) {
	useEffect(()=>{
		props.getListedNFT().then(()=>{
		})
	},[])
	if(props.loadingListing){
		return <Loader /> 
	}
	else{
		return (
			<div className="card">
				<div className="row d-flex flex-row p-3" >
					<div className="col-11">
						<h1>Listed NFTS</h1>
					</div>
				</div>
				<div className='gap-3 row p-3' style={{ display: "flex" }}>			
					{	(!props.listedNft) ? <h5 className="card-title">No NFT Found</h5> : 
						props.listedNft.map((element, index) => {
							return (
							<div className="card p-2" style={{width: "18rem", background:'#ededed'}} key={index}>
								<img src={element.image} className="card-img-top" alt="ima" style={{height:'180px'}} />
								<div className="card-body">
									<h5 className="card-title">{element.name}</h5>
									<p className="card-text">{element.description}</p>
									<p className="card-text">Price : {Number.parseInt(element.price, 10)/(10000000000*100000000)}</p>
									{(element.seller === props.account) ? '': 
										<button className='btn btn-success' onClick={()=>props.buyNFT(element)}>buy</button>
									}
								</div>
							</div>)
						})
					}
				</div>
			</div>
		)
	}
}
