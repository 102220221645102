import React from 'react'
import { useNavigate } from 'react-router-dom';
import ethLogo from '../assets/icons/ethereum.png'
import polyLogo from '../assets/icons/polygon.png'

export default function Login(props) {
    const navigate = useNavigate();
    const connect = async (chain) => {
        const connected = await props.ConnectMeta(chain);
        if(connected){
            navigate('/home');
        }
        else{
            alert('Unable to Connect meta mask.. Try again later..!')
        }
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-5 mx-auto" style={{ background : 'url(https://img.freepik.com/free-photo/hands-digital-universe-background_53876-97068.jpg?w=1060&t=st=1674457058~exp=1674457658~hmac=47a7e9ac77990835c0f30283a67ffb67f38c90fb272d66d8645161a16f4ab463) center'}}>
                    <div className="vh-100">
                        <div className="text-white fs-1 ps-3 pt-5">
                            <p> Minting NFT </p> 
                            <p> With </p> 
                            <p> August </p> 
                        </div>
                    </div>
                </div>
                <div className='col-md-5 mx-auto my-auto'>
                    <div className="myform form  mt-5">
                        <div className="logo mb-3">
                            <div className="col-md-12 text-center">
                                <h1 >Connect Wallet</h1>
                            </div>
                        </div>
                        <div className="col-md-12 text-center mb-3">
                            <div className="row mb-2 d-flex">
                                
                                <button type="submit" className=" btn btn-block mybtn btn-secondary tx-tfm" onClick={()=>connect(5)}> <img src={ethLogo} alt="img" style={{ height : "30px"}} /> <span> Connect With Ethereum </span></button>
                            </div>
                            <div className="row d-flex ">
                                <button type="submit" className=" btn btn-block mybtn btn-info tx-tfm" onClick={()=>connect(80001)}><img src={polyLogo} alt="img" style={{ height : "30px"}} /> <span> Connect With Polygon </span></button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>       
    )
}
