import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { uploadImg, uploadMetaData } from './HelperFunctions';
import Loader from './Loader';

export default function AddNFT(props) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    let nftNameInput;
    let nftFile;
    let nftDescInput;
    let [buttonText, setButtonText] = useState('Mint');
    let [loaderText, setLoaderText] = useState('Mint');

    function resetForm(){
        const form = document.getElementById("add_nft")
        if(form) form.reset();
    }

    const submitHandler = async () => {
        setLoading(true)
        setLoaderText('Minting..Please Wait..')
        const nftName = nftNameInput.value;
        const nft = nftFile;
        const nftDesc = nftDescInput.value;
        setLoaderText('Uploading Image..')
        const imgHash = await uploadImg(nft);
        if(imgHash){
            setLoaderText('Uploading Metadata..')
            const metaHash = await uploadMetaData(imgHash, nftName, nftDesc);
            if(metaHash){
                setLoaderText('Minting Your NFT.. Almost Done..!')
                await props.mint(metaHash)
            }
            else{alert('something goes wrong..!')}
        }
        else{alert('something goes wrong..!')}
        resetForm();
        navigate('/home');
    }

    if(loading){
        return (<Loader text={loaderText} />)
    }
    else {
        return (
            <form className='p-3' id="add_nft" onSubmit={
                async (event) => {
                    event.preventDefault();
                    await submitHandler();
                }
            }>
                <div className="row">
                    <div className="col-6">
                        <label className="form-label">Enter NFT Name</label>
                        <input type="text" className="form-control" id="nft-name" 
                        ref={(input) => nftNameInput = input } />
                    </div>
                    <div className="col-6">
                        <label className="form-label">Select NFT</label>
                        <input type="file" className="form-control" id="nft" 
                        onChange={(e) => nftFile = e.target.files[0] } />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <label className="form-label">Enter NFT Description</label>
                        <textarea className="form-control" id="nfi-description" 
                        ref={(input) => nftDescInput = input } / > 
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary me-2" id="smt-btn">{buttonText}</button>
                    </div>
                </div>

            </form>
        )
    }
}
