import axios from 'axios';
import CryptoJS from 'crypto-js';

// ---------------------- add --------------------------

export const uploadImg = async (nftFile) => {
    if (nftFile) {
        try {
            const formData = new FormData();
            const config = {
                'pinata_api_key': `${process.env.REACT_APP_PINATA_API_KEY}`,
                'pinata_secret_api_key': `${process.env.REACT_APP_PINATA_API_SECRET}`,
                "Content-Type": "multipart/form-data"
            }
            formData.append("file", nftFile);

            const resFile = await axios.post(
                "https://api.pinata.cloud/pinning/pinFileToIPFS",
                formData,
                { headers: config },
            )	
            return `https://ipfs.io/ipfs/${resFile.data.IpfsHash}`
        } catch (error) {
            console.log("Error sending File to IPFS: ", error)
        }
    }
}

export const uploadMetaData = async (ImgHash, nftName, nftDesc) => {
    try {
        const resJSON = await axios.post(
            "https://api.pinata.cloud/pinning/pinJsonToIPFS",
            {
                "name": nftName,
                "description": nftDesc,
                "image": ImgHash
            },
            {
                headers: {
                    'pinata_api_key': `${process.env.REACT_APP_PINATA_API_KEY}`,
                    'pinata_secret_api_key': `${process.env.REACT_APP_PINATA_API_SECRET}`,
                }
            },
        );
        return`ipfs://${resJSON.data.IpfsHash}`;
    } catch (error) {
        console.log("JSON to IPFS: ", error)
    }
}


export const isValidAccount = async (walletId) => {
    try{
        var hash = CryptoJS.SHA256(process.env.REACT_APP_AES_ENCRYPTION_KEY+process.env.REACT_APP_AES_ENCRYPTION_KEY2).toString();
        const resJSON = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}api/users/checkUser`,
            {
              'encryptedString': CryptoJS.AES.encrypt(walletId, hash).toString(), 
              'walletId': walletId  
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },      
            }
        );
        if(resJSON.status === 200)  {
            // alert(resJSON.json().msg)
            return true
        }
        else return false
    }catch(error){
        return false
    }
}

// ----------------------- network -------------------------------
export const Network = {
    80001: {
        rpcUrl : `https://rpc-mumbai.maticvigil.com`,
        chainId: 80001,
        chainName: 'Matic Mumbai',
        nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },

    },
    5: {
        rpcUrl : `https://goerli.infura.io/v3/f0e7114decca45a982af2741920ce7d6`,
        chainId: 5,
        chainName: 'Goerli Ethereum',
        nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
      },
}